import { UserRole } from 'constants/auth'

const isCandidate = (role: UserRole) => UserRole.Candidate === role
const isEmployer = (role: UserRole) =>
  [UserRole.Organisation, UserRole.OrganisationEmployee].includes(role)

export interface ISendEvent {
  eventName: string
  props?: object
}

export interface IDataLayer {
  push: (props: object) => boolean
}

export interface IWindow {
  dataLayer?: IDataLayer
}

export const sendEvent = ({ eventName, props }: ISendEvent) => {
  const dataLayer = (window as unknown as IWindow).dataLayer

  if (dataLayer) {
    dataLayer.push({ event: eventName, ...props })
  }
}

export const authEvent = (role: UserRole) => {
  sendEvent({ eventName: 'auth-trigger', props: { role, type: 'sign-in' } })
  if (UserRole.Candidate === role) {
    sendEvent({ eventName: 'sign-in-candidate' })
  } else if (
    [UserRole.Organisation, UserRole.OrganisationEmployee].includes(role)
  ) {
    sendEvent({ eventName: 'sign-in-employer' })
  }
}

export const signUpEvent = (role: UserRole) => {
  sendEvent({ eventName: 'auth-trigger', props: { role, type: 'sign-up' } })
  if (isCandidate(role)) {
    sendEvent({ eventName: 'sign-up-candidate' })
  } else if (isEmployer(role)) {
    sendEvent({ eventName: 'sign-up-employer' })
  }
}

export const changeRouteEvent = (path: string, role?: UserRole) => {
  sendEvent({ eventName: 'change-navigation', props: { moveTo: path, role } })
  if (role && isCandidate(role)) {
    sendEvent({
      eventName: 'candidate-change-navigation',
      props: { moveTo: path }
    })
  } else if (role && isEmployer(role)) {
    sendEvent({
      eventName: 'employer-change-navigation',
      props: { moveTo: path }
    })
  }
}
