import {
  signIn as authNextSignIn,
  signOut as authNextSignOut
} from 'next-auth/react'
import axios from 'axios'
import create from 'zustand'
import { persist } from 'zustand/middleware'

import { logout } from 'hooks/useAuth/auth.service'
import { getMyCV } from 'hooks/useCV/cv.service'

import { jwtUtils } from 'utils'
import { clearJwtInfo } from 'utils/cookies'
import { UserRole } from 'constants/auth'

export interface AuthStore {
  cookie: boolean
  extraUserInfo: ExtraUserInfo | null
  setExtraUserInfo: (extraUserInfo: Partial<ExtraUserInfo>) => void
  setCookie: (cookie: boolean) => void
  signIn: (payload: SignInActionProps) => Promise<void>
  signOut: (callbackUrl?: string) => void
}

export interface ExtraUserInfo {
  organisationId?: string | null
  candidateId?: string
  documentId?: string
  fullName?: string | null
}

export interface SignInActionProps {
  jwt: string
  refreshToken: string
  expiration: string
  remember?: boolean
}

const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      cookie: true,
      extraUserInfo: null,
      setCookie: (cookie: boolean) => set((state) => ({ ...state, cookie })),
      setExtraUserInfo: (extraUserInfo: Partial<ExtraUserInfo>) =>
        set((state) => ({
          ...state,
          extraUserInfo: { ...state.extraUserInfo, ...extraUserInfo }
        })),
      signIn: async ({ jwt, expiration, remember, refreshToken }) => {
        const { user } = jwtUtils.parseUser(jwt)
        const organisationId = user?.organisationId ?? null

        const extraUserInfo: ExtraUserInfo = {
          organisationId,
          fullName: user?.fullName
        }
        if (user.role === UserRole.Candidate) {
          try {
            const cv = await getMyCV({
              languageCode: 'en'
            })
            if (cv && cv?.id && cv?.candidateId) {
              extraUserInfo.candidateId = cv?.candidateId
              extraUserInfo.documentId = cv?.id
            }
          } catch (e) {
            if (axios.isAxiosError(e)) {
              if (e.response?.status === 404) {
                return
              }
              // Errors always on login, because unauthorized
              // handleQueryError(e)
            }
          }
        }

        await authNextSignIn('credentials', {
          redirect: false,
          jwt,
          refreshToken,
          remember: remember ?? false,
          expiration
        })

        set((state) => ({ ...state, remember, extraUserInfo }))
      },
      signOut: async (callbackUrl) => {
        await logout()
        clearJwtInfo(null)
        await authNextSignOut({
          callbackUrl,
          redirect: false
        })
        set((state) => ({
          ...state,
          extraUserInfo: null
        }))
      }
    }),
    {
      name: 'hrizer-auth',
      getStorage: () => {
        return localStorage
      }
    }
  )
)

export default useAuthStore
