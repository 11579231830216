import create from 'zustand'

export interface IModalStore {
  showAfterPayingProFormaModal: boolean
  showProhibitNavigationModal: boolean
  showConfirmEmailModal: boolean
  showMainEmployerOnlyModal: boolean
  emailResend: string | null
  setShowAfterPayingProFormaModal: (
    showAfterPayingProFormaModal?: boolean
  ) => void
  setShowProhibitNavigationModal: (
    showProhibitNavigationModal?: boolean
  ) => void
  setShowConfirmEmailModal: (showConfirmEmailModal?: boolean) => void
  setShowMainEmployerOnlyModal: (showMainEmployerOnlyModal?: boolean) => void
  setEmailResend: (email: string | null) => void
  clearStore: () => void
}

const useModalStore = create<IModalStore>()((set) => ({
  showAfterPayingProFormaModal: false,
  showProhibitNavigationModal: false,
  showConfirmEmailModal: false,
  emailResend: null,
  showMainEmployerOnlyModal: false,
  setShowAfterPayingProFormaModal: (showAfterPayingProFormaModal?: boolean) =>
    set(() => ({ showAfterPayingProFormaModal })),
  setShowProhibitNavigationModal: (showProhibitNavigationModal?: boolean) =>
    set(() => ({ showProhibitNavigationModal })),
  setShowConfirmEmailModal: (showConfirmEmailModal?: boolean) =>
    set(() => ({ showConfirmEmailModal })),
  setShowMainEmployerOnlyModal: (showMainEmployerOnlyModal?: boolean) =>
    set(() => ({ showMainEmployerOnlyModal })),
  setEmailResend: (emailResend: string | null) => set(() => ({ emailResend })),
  clearStore: () =>
    set(() => ({ showConfirmEmailModal: false, mainEmployerOnly: false }))
}))

export default useModalStore
