import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { IAuthTokens, IAuthUser } from 'data-transfers/dto'
import jwtDecode from 'jwt-decode'

import useAdNewStore from 'store/useAdNewStore'
import useAuthStore from 'store/useAuthStore'
import useCVNewStore from 'store/useCVNewStore'
import useMessageStore from 'store/useMessageStore'
import useModalStore from 'store/useModalStore'
import useOrgNewStore from 'store/useOrgNewStore'
import { useFirebaseAuth } from 'context/firebaseAuth'

import { SignIn } from 'validators/signInValidator'
import { authEvent } from 'utils/gtm.util'
import { UserRole } from 'constants/auth'

const useAuthManager = () => {
  const queryClient = useQueryClient()
  const signOutMain = useAuthStore((state) => state.signOut)
  const { signOut: firebaseSignOut, signIn: firebaseSignIn } = useFirebaseAuth()

  const platformSignIn = useAuthStore((state) => state.signIn)

  const clearAd = useAdNewStore((state) => state.clearAd)
  const clearCV = useCVNewStore((state) => state.clearCV)
  const clearOrg = useOrgNewStore((state) => state.clearOrg)
  const clearMessageStore = useMessageStore((state) => state.clearStore)
  const { clearStore: clearModalStore } = useModalStore()

  const signOut = useCallback(async () => {
    signOutMain()
    await firebaseSignOut()
    queryClient.removeQueries()
  }, [firebaseSignOut, queryClient, signOutMain])

  const signIn = useCallback(
    async ({
      token,
      refreshToken,
      expiration,
      remember
    }: IAuthTokens & Partial<Pick<SignIn, 'remember'>>) => {
      await platformSignIn({
        jwt: token,
        refreshToken: refreshToken,
        remember: remember,
        expiration: expiration
      })
      await firebaseSignIn()
      const user: IAuthUser = jwtDecode(token)
      authEvent(UserRole[user.role])
      clearCV()
      clearAd()
      clearOrg()
      clearMessageStore()
      clearModalStore()

      return user
    },
    [
      clearAd,
      clearCV,
      clearMessageStore,
      clearModalStore,
      clearOrg,
      firebaseSignIn,
      platformSignIn
    ]
  )
  return { signIn, signOut }
}

export default useAuthManager
