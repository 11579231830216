import create from 'zustand'
import { persist } from 'zustand/middleware'

import { IAdvert, IOrganisation } from '../data-transfers/dto'
import { CreationStep } from '../enums'

export interface AdNewStore {
  majorStep: number
  passedSteps: number[]
  activeStep: CreationStep
  isEditing: boolean
  tempAd: Partial<IAdvert> | null
  org: IOrganisation | null

  setOrg: (org: IOrganisation) => void
  setIsEditing: (isEditing: boolean) => void
  setTempAd: (ad: Partial<IAdvert>) => void
  setMajorStep: (majorStep: number) => void
  setPassedSteps: (passed: number[]) => void
  setStep: (step: CreationStep) => void
  incStep: () => void
  decStep: (isMajor?: boolean) => void
  clearAd: () => void
}

const useAdNewStore = create<AdNewStore>()(
  persist(
    (set) => ({
      majorStep: 0,
      passedSteps: [0],
      activeStep: 0,
      isEditing: false,
      tempAd: null,
      org: null,
      setOrg: (org) =>
        set((state) => ({
          ...state,
          org: {
            ...org,
            addresses: org.addresses.map((a) => ({
              ...a,
              label: `${a.streetAddress} ${a.houseNumber}, ${a.city} ${a.country}`,
              // TODO: fix Address type
              value: a.id ?? ''
            }))
          }
        })),
      setMajorStep: (majorStep: number) => set(() => ({ majorStep })),
      setTempAd: (tempAd) =>
        set((state) => ({
          ...state,
          tempAd: {
            ...state.tempAd,
            ...tempAd
          }
        })),
      setIsEditing: (isEditing: boolean) => set(() => ({ isEditing })),
      setPassedSteps: (val: number[]) =>
        set((state) => ({ ...state, passedSteps: val })),
      setStep: (step: number) =>
        set((state) => {
          const passedSteps = Array.from(
            new Set([
              ...state.passedSteps,
              ...Array.from(Array(step).keys()),
              step
            ])
          )
          return { ...state, activeStep: step, passedSteps }
        }),
      incStep: () =>
        set((state) => {
          const newStep = state.activeStep + 1
          if (!state.passedSteps.includes(newStep)) {
            return {
              ...state,
              activeStep: newStep,
              passedSteps: [...state.passedSteps, newStep]
            }
          } else {
            return { ...state, activeStep: newStep }
          }
        }),
      decStep: (isMajor) =>
        set((state) => ({
          ...state,
          activeStep: Math.max(0, state.activeStep - 1),
          majorStep: Math.max(
            isMajor ? state.majorStep - 1 : state.majorStep,
            0
          )
        })),
      clearAd: () =>
        set((state) => ({
          ...state,
          majorStep: 0,
          passedSteps: [0],
          activeStep: 0,
          isEditing: false,
          tempAd: null,
          org: null
        }))
    }),
    {
      name: 'hrizer-ad-new',
      getStorage: () => {
        return localStorage
      }
    }
  )
)

export default useAdNewStore
