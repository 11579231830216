import axios from 'axios'
import { IDocument, ILanguageCode } from 'data-transfers/dto'
import {
  IGetCvRequest,
  IPostCvRequest,
  IUpdateCVRequest
} from 'data-transfers/requests'
import { IGetCandidateCVResponse } from 'data-transfers/responses'
import { TrackingType } from 'enums'

import { authClient, getTrackingHeader } from 'utils'

export const getMyCV = async ({ languageCode = 'en' }: ILanguageCode) => {
  const response = await authClient.get<IDocument>(`/documents/user-id`, {
    params: {
      languageCode
    }
  })

  if (response.status >= 200 && response.status < 300) {
    return response.data
  }

  return null
}

export const getCandidateCV = async ({
  id,
  advertId,
  languageCode = 'en',
  trackingReview
}: IGetCvRequest) => {
  const trackType = trackingReview == true ? TrackingType.Candidate : null
  const response = await authClient.get<IGetCandidateCVResponse>(
    `/documents/${id}`,
    {
      headers: getTrackingHeader(trackType),
      params: {
        languageCode,
        advertId
      }
    }
  )
  if (response.status >= 200 && response.status < 300) {
    return response.data
  }
  return null
}

export const parseCV = async ({
  file,
  languageCode = 'en',
  jwt
}: IPostCvRequest) => {
  const formData = new FormData()
  formData.set('file', file)

  const response = await axios.post<IDocument>(
    `/api/v1/documents/parse`,
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${jwt}`
      },
      params: {
        languageCode
      }
    }
  )
  if (response.status >= 200 && response.status < 300) {
    return response.data
  }
  return null
}

export const updateCV = async ({ cv }: IUpdateCVRequest) => {
  const response = await authClient.put<IDocument>(`/documents/user-id`, cv)
  if (response.status >= 200 && response.status < 300) {
    return response.data
  }
  return null
}

export const submitCV = async () => {
  await authClient.post<IDocument>(`/documents/submit`)
}
