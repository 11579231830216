import create from 'zustand'

import { IRoomFullInfoProps } from 'components/Messages/components/RoomList/types'

export interface IOpenChatRoomProps {
  val: string
  isModal: boolean
}

export interface MessageStore {
  unreadMessages: string[]
  senderId: string | null
  hasChat: boolean
  openChatConfig: IOpenChatRoomProps | null
  openChat: IRoomFullInfoProps | null
  openChatModal: IRoomFullInfoProps | null
  popupChats: IRoomFullInfoProps[]
  showedAutoPopup: Set<string>

  setUnreadMessages: (val: string[] | null) => void
  setHasChat: (val: boolean) => void
  setOpenChatConfig: (val: IOpenChatRoomProps | null) => void
  setOpenChat: (val: IRoomFullInfoProps | null) => void
  setOpenChatModal: (val: IRoomFullInfoProps | null) => void
  setSenderId: (val: string | null) => void
  clearStore: () => void
  setPopupChats: (val: IRoomFullInfoProps[] | null) => void
}

const initValues = {
  unreadMessages: [],
  senderId: null,
  hasChat: true,
  openChatConfig: null,
  openChat: null,
  openChatModal: null,
  popupChats: [],
  showedAutoPopup: new Set<string>()
}

const useMessageStore = create<MessageStore>()((set) => ({
  ...initValues,
  setUnreadMessages: (val) => set(() => ({ unreadMessages: val ?? [] })),
  setOpenChatConfig: (val: IOpenChatRoomProps | null) =>
    set(() => ({ openChatConfig: val })),
  setOpenChat: (val: IRoomFullInfoProps | null) =>
    set(() => ({ openChat: val })),
  setOpenChatModal: (val: IRoomFullInfoProps | null) =>
    set(() => ({ openChatModal: val })),
  setHasChat: (val: boolean) => set(() => ({ hasChat: val })),
  setSenderId: (val: string | null) => set(() => ({ senderId: val })),
  clearStore: () => set(() => initValues),
  setPopupChats: (value) => set(() => ({ popupChats: value ?? [] }))
}))

export default useMessageStore
